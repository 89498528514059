/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-direction')
export class OxInputDirection extends OxFormField {
  static styles = css`
    :host {
      --md-icon-size: 1.4em;
    }

    div {
      display: flex;
      justify-content: space-between;
      max-width: 140px;
    }

    md-icon {
      color: var(--md-sys-color-on-background);
      background-color: var(--md-sys-color-background);
    }

    md-icon[selected] {
      color: var(--md-sys-color-on-primary);
      background-color: var(--md-sys-color-primary);
    }

    /* 아이콘 회전 */
    md-icon[data-value='W'] {
      transform: rotate(-90deg);
    }

    md-icon[data-value='N'] {
      transform: rotate(0deg);
    }

    md-icon[data-value='S'] {
      transform: rotate(180deg);
    }

    md-icon[data-value='E'] {
      transform: rotate(90deg);
    }
  `

  @property({ type: String }) value?: string
  @property({ type: Boolean }) inbound?: boolean = false

  render() {
    const value = this.value

    return html`
      <div @click=${this.onClick.bind(this)}>
        ${this.inbound
          ? html`
              <md-icon ?selected=${value == 'W'} data-value="W">step_into</md-icon>
              <md-icon ?selected=${value == 'N'} data-value="N">step_into</md-icon>
              <md-icon ?selected=${value == 'S'} data-value="S">step_into</md-icon>
              <md-icon ?selected=${value == 'E'} data-value="E">step_into</md-icon>
            `
          : html`
              <md-icon ?selected=${value == 'W'} data-value="W">step_out</md-icon>
              <md-icon ?selected=${value == 'N'} data-value="N">step_out</md-icon>
              <md-icon ?selected=${value == 'S'} data-value="S">step_out</md-icon>
              <md-icon ?selected=${value == 'E'} data-value="E">step_out</md-icon>
            `}
      </div>
    `
  }

  onClick(e: MouseEvent) {
    e.stopPropagation()

    if (this.disabled) {
      return
    }

    const target = e.target as Element
    const value = target.getAttribute('data-value') as string

    this.value = this.value === value ? undefined : value

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }
}
