import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { i18next, localize } from '@operato/i18n'
import { auth } from '@things-factory/auth-base/dist-client/auth.js'
import { generatePasswordPatternHelp, generatePasswordPatternRegExp } from '../utils/password-rule'

@customElement('change-password')
export class ChangePassword extends localize(i18next)(LitElement) {
  static styles = [
    css`
      * {
        box-sizing: border-box;
      }

      *:focus {
        outline: none;
      }

      form {
        display: flex;
        flex-direction: column;
      }

      input {
        border: var(--change-password-field-border);
        border-radius: var(--change-password-field-border-radius);
        margin: var(--change-password-field-margin);
        padding: var(--change-password-field-padding);

        font: var(--change-password-field-font);
        width: var(--change-password-field-width);
      }

      input:focus {
        border: 1px solid var(--focus-background-color);
      }

      ::placeholder {
        font-size: 0.8rem;
        text-transform: capitalize;
      }

      md-elevated-button {
        margin: var(--spacing-small) auto var(--spacing-medium) auto;
        text-transform: capitalize;
      }

      button {
        background-color: var(--secondary-color, #394e64);
        margin: 2px 2px 10px 2px;
        height: var(--button-height, 28px);
        color: var(--button-color, #fff);
        font: var(--button-font);
        border-radius: var(--button-radius, 5px);
        border: var(--button-border, 1px solid transparent);
        line-height: 1.5;
      }

      button:hover,
      button:active {
        background-color: var(--button-active-background-color, #22a6a7);
        border: var(--button-active-border);
      }

      .helper-text {
        font-size: 12px;
        color: var(--md-sys-color-) #6c757d;
        margin-top: 4px;
        display: block; /* 텍스트를 입력 필드 아래에 배치 */
        line-height: 1.5; /* 텍스트 줄 간격 조절 */
      }
    `
  ]

  @property({ type: Object }) passwordRule: {
    lowerCase?: boolean
    upperCase?: boolean
    digit?: boolean
    specialCharacter?: boolean
    allowRepeat?: boolean
    useTightPattern?: boolean
    useLoosePattern?: boolean
    tightCharacterLength?: number
    looseCharacterLength?: number
  } = {}

  @query('form') form!: HTMLFormElement

  private passwordPattern: string = ''
  private passwordHelp: string = ''

  render() {
    return html`
      <form>
        <div class="field">
          <input type="password" name="current_pass" placeholder=${i18next.t('text.current password')} required />
        </div>
        <span id="password-helper" class="helper-text">${this.passwordHelp}</span>
        <div class="field">
          <input
            type="password"
            name="new_pass"
            placeholder=${i18next.t('text.new password')}
            required
            pattern=${this.passwordPattern}
            aria-describedby="password-helper"
          />
        </div>
        <div class="field">
          <input type="password" name="confirm_pass" placeholder=${i18next.t('text.confirm password')} required />
        </div>

        <md-elevated-button @click=${this.submit.bind(this)}>${i18next.t('text.change password')}</md-elevated-button>
      </form>
    `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('passwordRule')) {
      this.passwordPattern = generatePasswordPatternRegExp(this.passwordRule).source
      this.passwordHelp = generatePasswordPatternHelp(this.passwordRule)
    }
  }

  languageUpdated() {
    this.passwordPattern = generatePasswordPatternRegExp(this.passwordRule).source
    this.passwordHelp = generatePasswordPatternHelp(this.passwordRule)
  }

  async submit() {
    const formData = new FormData(this.form)
    let params = {}
    for (const [key, value] of formData.entries()) {
      if (!value) {
        const placeholder = (this.form.querySelector(`[name=${key}]`) as HTMLInputElement)?.placeholder
        return this.showToast(i18next.t('error.value is empty', { value: placeholder || key }))
      }
      params[key] = value
    }

    if (params['new_pass'] !== params['confirm_pass']) {
      return this.showToast(i18next.t('error.new-password-and-confirm-password-do-not-match'))
    }

    auth.changePassword(params)
    this.form.reset()
  }

  showToast(message) {
    document.dispatchEvent(new CustomEvent('notify', { detail: { message } }))
  }
}
