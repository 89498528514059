/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/chips/filter-chip.js'
import '@material/web/chips/chip-set.js'

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'
import { MdFilterChip } from '@material/web/chips/filter-chip.js'

type Option = { display: string; value: string }

@customElement('ox-input-select-buttons')
export class OxInputSelectButtons extends OxFormField {
  static styles = css`
    :host {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: var(--spacing-medium);

      text-transform: capitalize;
    }
  `

  @property({ type: Array }) value: string[] | string | undefined = []
  @property({ type: Array }) options: Option[] = []
  @property({ type: Boolean }) multiple: boolean = false

  render() {
    const options = this.options.filter(option => !!option.value)
    const values = this.value instanceof Array ? this.value : typeof this.value == 'string' ? [this.value] : []

    return html`
      <md-chip-set>
        ${(options || []).map(
          ({ display, value }) => html`
            <md-filter-chip
              data-value=${value}
              label=${display}
              ?selected=${values.includes(value)}
              @tap=${(e: MouseEvent | TouchEvent) => !this.disabled && this.onTap(e)}
            ></md-filter-chip>
          `
        )}
      </md-chip-set>
    `
  }

  onTap(e: MouseEvent | TouchEvent) {
    e.stopPropagation()

    const target = e.target as MdFilterChip
    const value = target.getAttribute('data-value') as string

    if (this.multiple) {
      const values = this.value instanceof Array ? this.value : typeof this.value == 'string' ? [this.value] : []

      const idx = (this.value || []).indexOf(value)
      if (idx != -1) {
        values.splice(idx, 1)
      } else {
        values.push(value)
      }
      this.value = [...values]
    } else {
      this.value = this.value === value ? undefined : value
    }

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }
}
